import ApiService from "@/core/services/api.service";

/**
 * GET request to fetch all data
 * @returns {*}
 */
export const getAllEvaluationStatuses = () => {
    return ApiService.get("evaluation_statuses");
};

/**
 * GET request to fetch a specific data
 * @param id
 * @returns {*}
 */
export const getEvaluationStatus = (id) => {
    return ApiService.get("evaluation_statuses", id);
};

/**
 * POST request to create a new evaluation_status
 * @param payload
 * @returns {*}
 */
export const createEvaluationStatus = (payload) => {
    return ApiService.post("evaluation_statuses", payload);
};

/**
 * PUT request to update a specific evaluation_status
 * @param id
 * @param payload
 * @returns {*}
 */
export const updateEvaluationStatus = (id, payload) => {
    return ApiService.update("evaluation_statuses", id, payload);
};

/**
 * DELETE request to delete the specified evaluation_status
 * @param id
 * @returns {*}
 */
export const deleteEvaluationStatus = (id) => {
    return ApiService.delete(`evaluation_statuses/${id}`);
};

/**
 * GET request to change status of the specified evaluation_status
 * @param id
 * @returns {*}
 */
export const changeStatusEvaluationStatus = (id) => {
    return ApiService.get("evaluation_statuses/status", id);
};

export default {
    getAllEvaluationStatuses,
    getEvaluationStatus,
    createEvaluationStatus,
    updateEvaluationStatus,
    deleteEvaluationStatus,
    changeStatusEvaluationStatus,
};

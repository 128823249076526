<template>
  <div>
    <v-btn text class="mb-2" @click="goBack">
      <v-icon left>
        mdi-arrow-left
      </v-icon>
      Regresar a mis asignaturas
    </v-btn>

    <!-- begin::Subject Information Card -->
    <SubjectInformationCard :subjectData="subjectData"></SubjectInformationCard>
    <!-- end::Subject Information Card -->

    <!-- begin::Card -->
    <div class="card card-custom gutter-b">
      <div class="card-header flex-nowrap border-0 pt-6 pb-0">
        <div class="card-title">
          <h3 class="card-label">
            Perfiles de la asignatura
            <span class="d-block text-muted pt-2 font-size-sm"
              >Aqui se podra visualizar todos los perfiles de la asignatura
              seleccionada.</span
            >
          </h3>
        </div>
        <div class="card-toolbar">
          <!--begin::Button New Record-->
          <v-btn
            elevation="0"
            color="primary"
            @click="goToCreateEvaluation"
            v-if="existInArray('Agregar', currentPageActions)"
          >
            <span class="svg-icon svg-icon-md svg-icon-white mr-1">
              <inline-svg src="media/svg/icons/Home/Book-open.svg"></inline-svg>
            </span>
            Crear perfil
          </v-btn>
          <!--end::Button New Record-->
        </div>
      </div>
      <div class="card-body">
        <!-- begin::Search Form -->
        <div class="mb-5">
          <div class="row align-items-center">
            <div class="col-lg-12">
              <div class="row align-items-center">
                <div class="col-md-4 col-xl-3 my-2 my-md-0">
                  <v-text-field
                    outlined
                    dense
                    hide-details
                    prepend-icon=""
                    prepend-inner-icon="mdi-magnify"
                    placeholder="Buscar..."
                    v-model="evaluations.search"
                  ></v-text-field>
                </div>
                <div class="col-md-4 col-xl-3 my-2 my-md-0">
                  <div class="d-flex align-items-center">
                    <v-select
                      label="Agrupación del perfil"
                      outlined
                      dense
                      hide-details
                      clearable
                      multiple
                      :items="activityCategories"
                      item-text="name"
                      item-value="id"
                      v-model="evaluations.filters.activity_category_id"
                    >
                      <template v-slot:selection="{ item, index }">
                        <v-chip color="primary" small v-if="index === 0">
                          <span>{{ item.name }}</span>
                        </v-chip>
                        <span
                          v-if="index === 1"
                          class="grey--text text-caption"
                        >
                          (+{{
                            evaluations.filters.activity_category_id.length - 1
                          }}
                          seleccionados)
                        </span>
                      </template>
                    </v-select>
                  </div>
                </div>
                <div class="col-md-4 col-xl-3 my-2 my-md-0">
                  <div class="d-flex align-items-center">
                    <v-select
                      label="Tipo de actividad"
                      outlined
                      dense
                      hide-details
                      clearable
                      multiple
                      :items="activityTypes"
                      item-text="name"
                      item-value="id"
                      v-model="evaluations.filters.activity_type_id"
                    >
                      <template v-slot:selection="{ item, index }">
                        <v-chip color="primary" small v-if="index === 0">
                          <span>{{ item.name }}</span>
                        </v-chip>
                        <span
                          v-if="index === 1"
                          class="grey--text text-caption"
                        >
                          (+{{
                            evaluations.filters.activity_type_id.length - 1
                          }}
                          seleccionados)
                        </span>
                      </template>
                    </v-select>
                  </div>
                </div>
                <div class="col-md-4 col-xl-3 my-2 my-md-0">
                  <div class="d-flex align-items-center">
                    <v-select
                      label="Estados del perfil"
                      outlined
                      dense
                      hide-details
                      clearable
                      multiple
                      :items="evaluationStatuses"
                      item-text="name"
                      item-value="id"
                      v-model="evaluations.filters.evaluation_status_id"
                    >
                      <template v-slot:selection="{ item, index }">
                        <v-chip color="primary" small v-if="index === 0">
                          <span>{{ item.name }}</span>
                        </v-chip>
                        <span
                          v-if="index === 1"
                          class="grey--text text-caption"
                        >
                          (+{{
                            evaluations.filters.evaluation_status_id.length - 1
                          }}
                          seleccionados)
                        </span>
                      </template>
                    </v-select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end::Search Form -->
        <!--begin: Datatable-->
        <v-card>
          <v-data-table
            :headers="evaluations.headers"
            :items="filteredData"
            :search="evaluations.search"
            :items-per-page="10"
            :loading="evaluations.isLoading"
            show-expand
            single-expand
            :expanded.sync="evaluations.expanded"
          >
            <template v-slot:[`item.evaluation_status`]="{ item }">
              <v-chip
                :color="getEvaluationStatusColor(item.evaluation_status_id)"
              >
                {{ item.evaluation_status }}
              </v-chip>
            </template>
            <!-- begin: Actions -->
            <template v-slot:[`item.actions`]="{ item }">
              <template v-if="existInArray('Ver perfil', currentPageActions)">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      @click="goToEvaluation(item)"
                      color="purple accent-4"
                      class="ml-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-book-information-variant
                    </v-icon>
                  </template>
                  <span>Ver perfil</span>
                </v-tooltip>
              </template>
            </template>
            <!-- end: Acions -->
            <!-- begin: Expand -->
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <v-container>
                  <v-row class="pt-4">
                    <v-col class="pt-0">
                      <div class="black--text mb-1 flex-column">
                        <div class="text-body-2">Descripción</div>
                        <span class="text-body-1">
                          {{ item.description }}
                        </span>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pt-0">
                      <div class="black--text mb-1 flex-column">
                        <div class="text-body-2">Fecha de inicio</div>
                        <span class="text-body-1">
                          {{ item.start_date_formatted }}
                        </span>
                      </div>
                    </v-col>
                    <v-col class="pt-0">
                      <div class="black--text mb-1 flex-column">
                        <div class="text-body-2">Fecha de finalización</div>
                        <span class="text-body-1">
                          {{ item.end_date_formatted }}
                        </span>
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </td>
            </template>
            <!-- end: Expand -->
          </v-data-table>
        </v-card>
        <!-- end: Datatable-->
      </div>
    </div>
    <!-- end::Card -->

    <!-- begin::Modals -->
    <!-- end::Modals -->
  </div>
</template>

<script>
import {
  GET_CURRENT_PAGE_ACTIONS,
  PURGE_CURRENT_PAGE_ACTIONS,
} from "@/core/services/store/role.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";
import evaluationRepository from "@/repositories/evaluationRepository";
import activityCategoryRepository from "@/repositories/activityCategoryRepository";
import activityTypeRepository from "@/repositories/activityTypeRepository";
import subjectRepository from "@/repositories/subjectRepository";
import evaluationStatusRepository from "@/repositories/evaluationStatusRepository";
import SubjectInformationCard from "@/components/elements/evaluation/SubjectInformationCard.vue";

export default {
  name: "SubjectEvaluation",
  title: "Perfiles de la asignatura | GE ITR",
  components: {
    SubjectInformationCard,
  },
  data() {
    return {
      evaluations: {
        headers: [
          { text: "Tipo de actividad", value: "activity_type" },
          { text: "Etapa", value: "academic_load.stage.name" },
          { text: "Agrupación del perfil", value: "activity_category" },
          { text: "Ponderación (%)", value: "percent_formatted" },
          { text: "Estado", value: "evaluation_status" },
          { text: "Acciones", value: "actions", sortable: false },
          { text: "", value: "data-table-expand" },
        ],
        data: [],
        search: "",
        isLoading: false,
        filters: {},
        expanded: [],
      },
      selectedEvaluation: {},
      activityCategories: [],
      activityTypes: [],
      subjectData: {},
      evaluationStatuses: [],
    };
  },
  props: {
    subject_teacher: {
      type: String,
    },
    subject: {
      type: String,
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Mis asignaturas", route: "teacher_subjects" },
      { title: "Perfiles de la asignatura", route: "subject_evaluations" },
    ]);
    this.getSubject();
    this.loadEvaluations();
    this.getActivityTypes();
    this.getEvaluationStatuses();
    this.$store.dispatch(GET_CURRENT_PAGE_ACTIONS, "Perfiles de la asignatura");
  },
  methods: {
    selectEvaluation(obj) {
      // Creating a non reactive copie of data
      this.selectedEvaluation = { ...obj };
    },
    unselectEvaluation() {
      this.selectedEvaluation = {};
    },
    showMdlCreate() {
      this.$refs.mdlCreate.toggleModal();
    },
    loadEvaluations() {
      this.evaluations.isLoading = true;
      evaluationRepository
        .getEvaluationsOfSubject(this.subject_teacher)
        .then(({ data }) => {
          this.evaluations.data = data;
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        })
        .finally(() => {
          this.evaluations.isLoading = false;
        });
    },
    getActivityCategories() {
      activityCategoryRepository
        .getActivityCategoriesBySubjectType(
          this.subjectData.subject_evaluation_scale.subject_type_id
        )
        .then(({ data }) => {
          this.activityCategories = data;
        })
        .catch((err) => {
          console.error(err);
          // Sweetalert that indicates the operation cannot be completed
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        });
    },
    getActivityTypes() {
      activityTypeRepository
        .getAllActivityTypes()
        .then(({ data }) => {
          this.activityTypes = data;
        })
        .catch((err) => {
          console.error(err);
          // Sweetalert that indicates the operation cannot be completed
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        });
    },
    getEvaluationStatuses() {
      evaluationStatusRepository
        .getAllEvaluationStatuses()
        .then(({ data }) => {
          this.evaluationStatuses = data;
        })
        .catch((err) => {
          console.error(err);
          // Sweetalert that indicates the operation cannot be completed
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        });
    },
    getSubject() {
      subjectRepository
        .getSubject(this.subject)
        .then(({ data }) => {
          this.subjectData = data;
          this.getActivityCategories();
        })
        .catch((err) => {
          console.error(err);
          // Sweetalert that indicates the operation cannot be completed
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        });
    },
    goToCreateEvaluation() {
      this.$router.push({
        path: "create_evaluation",
        query: {
          s: this.subject,
          s_t: this.subject_teacher,
        },
      });
    },
    goToEvaluation({ id, subject_id }) {
      this.$router.push({
        path: "evaluation",
        query: {
          e: id,
          s: subject_id,
          f: "subject_evaluations",
        },
      });
    },
    goBack() {
      this.$router.go(-1);
    },
    getEvaluationStatusColor(evaluationStatusId) {
      let color;
      switch (evaluationStatusId) {
        case 1:
        case 4:
          color = "warning";
          break;
        case 2:
          color = "info";
          break;
        case 3:
          color = "error";
          break;
        case 5:
          color = "success";
        default:
          break;
      }
      return color;
    },
  },
  computed: {
    ...mapGetters(["currentPageActions"]),
    filteredData() {
      return this.filterData(this.evaluations.filters, this.evaluations.data);
    },
  },
  beforeDestroy() {
    this.$store.commit(PURGE_CURRENT_PAGE_ACTIONS);
  },
};
</script>
